// JS depedency
var GoogleMapsLoader = require('google-maps');

// Initialisation
GoogleMapsLoader.KEY = 'AIzaSyDtPqQNaqulrxB9rgp2DhTFIGzUC1UKZxs';

if ($('#gmap-simple').length || $('#gmap-cluster').length) {
    GoogleMapsLoader.load(functions);
}

function functions(google) {

    gmap_simple = function () {

        function gmap_simple_init() {
            var map;
            var mapLat = 14.760354;
            var mapLng = -61.184778;
            var mapZoom = 12;
            var myLatLng = {
                lat: mapLat,
                lng: mapLng
            };

            map = new google.maps.Map(document.getElementById("gmap-simple"), {
                mapTypeId: 'roadmap',
                scrollwheel: false,
                panControl: false,
                zoomControl: true,
                zoom: mapZoom,
                center: myLatLng,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false
            });

            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                title: 'Finouss Design',
            });
        }

        gmap_simple_init();
    }

    if ($('#gmap-simple').length) {
        gmap_simple();
    }
}
